<template>
  <b-card class="overflow-hidden" body-class="p-1">
    <b-custom-tabs :tabs="tabs" lazy-once />
  </b-card>
</template>

<script>
import assignmentConfirmHistory from "./assignmentConfirmHistory.vue";
import assignmentConfirmTable from "./assignmentConfirmTable.vue";

export default {
  data() {
    return {
      tabs: [
        {
          title: "Onay Bekleyenler",
          component: assignmentConfirmTable,
        },
        {
          title: "Atama Geçmişi",
          component: assignmentConfirmHistory,
        },
      ],
    };
  },
};
</script>

<style></style>
