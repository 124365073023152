var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-overlay',[_c('db-searchable-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"total-rows":_vm.total,"no-border":"","no-body-gap":""},on:{"changed":_vm.getAssignmentConfirms},scopedSlots:_vm._u([{key:"branch",fn:function(ref){
var item = ref.item;
return [(item.branch && item.branch.name)?_c('div',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(item.branch.name.length > 20 ? item.branch.name : null),expression:"item.branch.name.length > 20 ? item.branch.name : null",modifiers:{"hover":true,"top":true}}]},[_vm._v(" "+_vm._s(_vm.$fn(item.branch.name, 20))+" ")])]):_vm._e()]}},{key:"roleKey",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$physicianRole(data.item.roleKey))+" ")]}},{key:"companyConfirm",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":_vm.$variants[item.companyConfirm]}},[_vm._v(" "+_vm._s(_vm.$capitalize(item.companyConfirm))+" ")])]}},{key:"physicianConfirm",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":_vm.$variants[item.physicianConfirm]}},[_vm._v(" "+_vm._s(_vm.$capitalize(item.physicianConfirm))+" ")])]}},{key:"isAssignmentActive",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":item.isAssignmentActive ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(item.isAssignmentActive ? "Aktif" : "Pasif")+" ")])]}}]),model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }