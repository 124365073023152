<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getAssignmentConfirms" :items="items" :fields="fields" :total-rows="total" no-border no-body-gap>
      <template #branch="{ item }">
        <div v-if="item.branch && item.branch.name">
          <span v-b-tooltip.hover.top="item.branch.name.length > 20 ? item.branch.name : null">
            {{ $fn(item.branch.name, 20) }}
          </span>
        </div>
      </template>
      <template slot="physician.role" slot-scope="data">
        {{ $physicianRole(data.item.roleKey) }}
      </template>
      <template #actions="{ item }">
        <div class="d-flex gap-3 justify-content-center">
          <app-icon-button icon="CheckIcon" variant="gradient-success" @click="changeStatus('accept', item._id)" />
          <app-icon-button icon="XIcon" variant="gradient-danger" @click="changeStatus('reject', item._id)" />
        </div>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      filters: {},
      total: 0,
      fields: [
        { key: "physician.auth.fullname", label: "Görevli Adı" },
        { key: "physician.role", label: "Görevi" },
        { key: "branch.name", label: "Sicil" },
        {
          key: "actions",
          label: "Eylemler",
          class: "text-center",
          thStyle: "width: 100px",
          tdStyle: "width: 100px",
        },
      ],
      items: [],
    };
  },
  computed: {
    companyId() {
      return this.$store.getters?.selectedCompany?._id;
    },
  },
  methods: {
    getAssignmentConfirms() {
      this.$axios
        .get("/rest-assignments/paginated-assignments", {
          params: { ...this.filters, company: this.companyId, isAssignmentActive: true, companyConfirm: "onayda", populates: JSON.stringify(["physician", "branch"]) },
          loading: "table",
        })
        .then(({ data: { data, total } }) => {
          this.items = data;
          this.total = total;
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
        });
    },
    changeStatus(status, id) {
      const alertMessages = {
        accept: "Atama onaylanacaktır emin misiniz?",
        reject: "Atama reddedilecektir emin misiniz?",
      };
      this.$confirm({ message: alertMessages[status] }, () => {
        this.$axios
          .patch(`/rest-assignments/update-company-confirm/${id}/${status}`)
          .then((res) => {
            this.getAssignmentConfirms();
            this.$emitter.$emit("Notification", {
              variant: "success",
              title: "Atama Güncellendi.",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style></style>
