<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getAssignmentConfirms" :items="items" :fields="fields" :total-rows="total" no-border no-body-gap>
      <template #branch="{ item }">
        <div v-if="item.branch && item.branch.name">
          <span v-b-tooltip.hover.top="item.branch.name.length > 20 ? item.branch.name : null">
            {{ $fn(item.branch.name, 20) }}
          </span>
        </div>
      </template>
      <template slot="roleKey" slot-scope="data">
        {{ $physicianRole(data.item.roleKey) }}
      </template>
      <template #companyConfirm="{ item }">
        <b-badge :variant="$variants[item.companyConfirm]">
          {{ $capitalize(item.companyConfirm) }}
        </b-badge>
      </template>
      <template #physicianConfirm="{ item }">
        <b-badge :variant="$variants[item.physicianConfirm]">
          {{ $capitalize(item.physicianConfirm) }}
        </b-badge>
      </template>
      <template #isAssignmentActive="{ item }">
        <b-badge :variant="item.isAssignmentActive ? 'success' : 'danger'">
          {{ item.isAssignmentActive ? "Aktif" : "Pasif" }}
        </b-badge>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      filters: {},
      total: 0,
      fields: [
        { key: "physician.auth.fullname", label: "Görevli Adı" },
        { key: "roleKey", label: "Görevi" },
        { key: "branch.name", label: "Sicil" },
        { key: "physicianConfirm", label: "Görevli Onayı" },
        { key: "companyConfirm", label: "Firma Onayı" },
        { key: "isAssignmentActive", label: "Durum" },
      ],
      items: [],
    };
  },
  computed: {
    companyId() {
      return this.$store.getters?.selectedCompany?._id;
    },
  },
  mounted() {
    this.getAssignmentConfirms();
  },
  methods: {
    getAssignmentConfirms() {
      this.$axios
        .get("/rest-assignments/paginated-assignments", {
          params: {
            ...this.filters,
            company: this.companyId,
            isAssignmentActive: true,
            sortBy: this.filters?.sortBy ?? "isAssignmentActive",
            populates: JSON.stringify(["physician", "branch"]),
          },
          loading: "table",
        })
        .then(({ data: { data, total } }) => {
          this.items = data;
          this.total = total;
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
        });
    },
  },
};
</script>

<style></style>
