<template>
  <section id="dashboard-analytics">
    <!--     <b-card bg-variant="warning" text-variant="white" body-class="p-0 py-50 px-1" class="mb-1">
      <div class="d-flex justify-content-between align-items-center">
        <span>
          <feather-icon icon="InfoIcon" size="18" class="mr-50" />
          İSG-Katip onaylarınızın Sistem Veri Yetkilimiz tarafından otomatik yapılmasını onaylıyor musunuz?
        </span>
        <div class="d-flex gap-5">
          <app-button text="Onaylıyorum" variant="gradient-success" icon="CheckIcon" />
          <app-button text="İstemiyorum" variant="gradient-secondary" icon="XIcon" />
        </div>
      </div>
    </b-card> -->
    <assignment-confirm-tabs />
    <!--  <b-row>
      <b-col lg="9">
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import assignmentConfirmTabs from "./assignmentConfirmTabs.vue";

export default {
  components: {
    assignmentConfirmTabs,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>
